import React from "react"

import { PrismicImage } from "../types/prismic"
import "./LazyImage.scss"

type Props = {
  image: PrismicImage
  fluid?: boolean
  background?: boolean
  sizes?: string
}

const addParams = (url: string, params: { [key: string]: any }) => {
  const [currentUrl, currentParams] = url.split("?")
  const paramMap = Object.fromEntries(
    currentParams.split("&").map(p => {
      return p.split("=")
    })
  )
  const newParams = {
    ...paramMap,
    ...params,
  }
  const paramString = Object.keys(newParams)
    .map(key => {
      return `${key}=${newParams[key]}`
    })
    .join("&")
  return `${currentUrl}?${paramString}`
}

const genSrsetFromSizes = (url: string, sizes: number[]) => {
  return sizes
    .map(size => {
      const sizedUrl = addParams(url, { w: size, auto: "format" })
      return `${sizedUrl} ${size}w`
    })
    .join(", ")
}

const LazyImage: React.FC<Props> = ({
  image,
  fluid = true,
  background = false,
  sizes,
}) => {
  const [imageLoaded, setImageLoaded] = React.useState(false)
  const imageRef = React.useRef<HTMLImageElement>(null)

  React.useEffect(() => {
    if (!imageRef.current) return
    if (imageRef.current.complete) setImageLoaded(true)
  }, [imageRef])

  const onImageLoaded = () => {
    setImageLoaded(true)
  }

  // set the lazy source
  const lazy = addParams(image.url, { blur: 2000 })

  // if it's fluid, get a big srcset
  const fluidSizes = [
    6000,
    5120,
    3100,
    2048,
    1920,
    1800,
    1680,
    1536,
    1440,
    1280,
    1200,
    1024,
    768,
    640,
    320,
  ]
  let srcset = ""
  if (fluid) srcset = genSrsetFromSizes(image.url, fluidSizes)
  const loadingAlt = `Loading - ${image.alt || ""}`

  const classNames = ["lazy-image"]
  if (background) classNames.push("lazy-image--is-background")
  if (imageLoaded) classNames.push("lazy-image--image-loaded")

  return (
    <div className={classNames.join(" ")}>
      <div className={`lazy-image__preview`}>
        <img src={lazy} alt={loadingAlt} />
      </div>
      <img
        src={image.url}
        srcSet={srcset}
        alt={image.alt || ""}
        onLoad={onImageLoaded}
        ref={imageRef}
        sizes={sizes}
      />
    </div>
  )
}

export default LazyImage
