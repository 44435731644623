import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { PrismicQuery, PrismicEdges } from "../types/prismic"

const seoQuery = graphql`
  query {
    prismic {
      allSite_configs {
        edges {
          node {
            site_title
            site_description
          }
        }
      }
    }
  }
`

interface SEOProps {
  title: string
  lang?: string
  description?: string
  meta?: object
}

type PrismicData = {
  site_title: string
  site_description?: string
}

type AllSiteConfigs = {
  allSite_configs: PrismicEdges<PrismicData>
}

const SEOComponent: React.FC<PrismicQuery<AllSiteConfigs> & SEOProps> = ({
  description,
  lang,
  title,
  data: prismicData,
}) => {
  const data = prismicData.prismic.allSite_configs.edges?.[0]?.node || {}

  const metaDescription = description || data.site_description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site_title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

const SEO: React.FC<SEOProps> = props => (
  <StaticQuery
    query={seoQuery}
    render={data => <SEOComponent data={data} {...props} />}
  />
)

export default SEO
